import {CircularProgress} from "@mui/material";
import {AxiosResponse} from "axios";
import moment from "moment";
import {useEffect, useState} from "react";
import {Accordion, Card, OverlayTrigger, Tooltip} from "react-bootstrap";
import {Link, Navigate, useLocation, useNavigate} from "react-router-dom";
import Select from "react-select";
import ReactSelect, {SingleValue, StylesConfig, Theme} from "react-select";
import Swal from "sweetalert2";

import Button from "components/Button";
import ContainerAnimation from "components/ContainerAnimation";
import FormatGroupLabel from "components/FormatGroupLabel";
import LinkButton from "components/LinkButton";
import LoadingScreen from "components/LoadingScreen";
import ModalBodyComponentStepper from "components/ModalBodyComponentStepper";
import ModalComponent from "components/ModalComponent";
import RequiredDocuments from "components/RequiredDocuments";
import PatientQuoteEditForm from "pages/Quotes/PatientQuote/components/Forms/PatientQuoteEditForm";
import ProfessionalQuoteEditForm from "pages/Quotes/ProfessionalQuote/components/Forms/ProfessionalQuoteEditForm";
import QuoteEditForm from "pages/Quotes/ProfessionalQuote/components/Forms/QuoteEditForm";
import SecretaryQuoteEditForm from "pages/Quotes/ProfessionalQuote/components/Forms/SecretaryQuoteEditForm";
import {useAuth} from "providers/Auth";
import {useQuoteDetails} from "providers/QuoteDetails";
import {api} from "services";
import {
  PaymentFeeOwner,
  PaymentMethod,
  PaymentMethodDescription,
  QuoteActions,
  QuoteStatus,
  quoteStatusColor,
  quoteStatusTitle,
  RoleDescription,
  PaymentLinkAction,
  FormTypeDescription,
  ProcedureDateStatusDescription,
  QuotePaymentStatusDescription,
} from "types/enums";
import {DatabaseFile, FileAttachment} from "types/files";
import {ActionFormQuote, FormQuote} from "types/formQuote";
import {GroupedOption, Quote, StatusOption} from "types/quotes";
import {formatAsPercent, formatPrice} from "utils/formats";
import {
  dateStatusOptions,
  defaultFormTypeOption,
  formTypeOptions,
  formTypeOptionsInterface,
  quotePaymentStatusOptions,
} from "utils/selectOptions";
import {toast} from "utils/toast";

import AuditLogList from "./components/AuditLogList";
import Chat from "./components/Chat";
import Checklist from "./components/Checklist/Checklist";
import CustomToggle from "./components/CustomToggle";
import FileAddForm from "./components/FileAddForm";
import FileCard from "./components/FileCard";
import FormQuoteCard from "./components/FormQuoteCard";
import Step1PersonalInfoForm from "./components/FormQuoteCard/components/Form/Step1PersonalInfoForm";
import Step2AddessInfoForm from "./components/FormQuoteCard/components/Form/Step2AdressInfoForm";
import Step3ProfessionalInfoForm from "./components/FormQuoteCard/components/Form/Step3ProfessionalInfoForm";
import Step4OthersInfoForm from "./components/FormQuoteCard/components/Form/Step4OthersInfoForm";
import PaymentLink from "./components/PaymentLink";
import TermsLink from "./components/TermsLink";
import TimeLineStep from "./components/TimeLineStep";

const customTheme = (theme: Theme) => ({
  ...theme,
  borderRadius: 7,
  colors: {
    ...theme.colors,
    /*
     * multiValue(remove)/color:hover
     */
    danger: "var(--danger)",
    /*
     * multiValue(remove)/backgroundColor(focused)
     * multiValue(remove)/backgroundColor:hover
     */
    dangerLight: "var(--danger-light)",
    /*
     * control/backgroundColor
     * menu/backgroundColor
     * option/color(selected)
     */
    neutral0: "var(--neutral-0)",
    /*
     * control/backgroundColor(disabled)
     */
    neutral5: "var(--neutral-5)",
    /*
     * control/borderColor(disabled)
     * multiValue/backgroundColor
     * indicators(separator)/backgroundColor(disabled)
     */
    neutral10: "var(--neutral-10)",
    /*
     * control/borderColor
     * option/color(disabled)
     * indicators/color
     * indicators(separator)/backgroundColor
     * indicators(loading)/color
     */
    neutral20: "var(--neutral-20)",
    /*
     * control/borderColor(focused)
     * control/borderColor:hover
     */
    neutral30: "var(--neutral-30)",
    /*
     * menu(notice)/color
     * singleValue/color(disabled)
     * indicators/color:hover
     */
    neutral40: "var(--neutral-40)",
    /*
     * placeholder/color
     */
    neutral50: "var(--neutral-50)",
    /*
     * indicators/color(focused)
     * indicators(loading)/color(focused)
     */
    neutral60: "var(--neutral-60)",
    neutral70: "var(--neutral-70)",
    /*
     * input/color
     * multiValue(label)/color
     * singleValue/color
     * indicators/color(focused)
     * indicators/color:hover(focused)
     */
    neutral80: "var(--neutral-80)",
    neutral90: "var(--neutral-90)",
    /*
     * control/boxShadow(focused)
     * control/borderColor(focused)
     * control/borderColor:hover(focused)
     * option/backgroundColor(selected)
     * option/backgroundColor:active(selected)
     */
    primary: "var(--primary)",
    /*
     * option/backgroundColor(focused)
     */
    primary25: "var(--primary-25)",
    /*
     * option/backgroundColor:active
     */
    primary50: "var(--primary-50)",
    primary75: "var(--primary-75)",
  },
});

const customStyles: StylesConfig = {
  control: (base: Record<string, unknown>) => ({
    ...base,
    height: 48,
    borderColor: "var(--border)",
    cursor: "pointer",
    boxShadow: "none",
    "&:hover": {
      borderColor: "var(--border)",
    },
  }),
  singleValue: (base: Record<string, unknown>) => ({
    ...base,
    textTransform: "uppercase",
  }),
  option: (base: Record<string, unknown>, state: any) => ({
    ...base,
    cursor: "pointer",
    textTransform: "uppercase",
    backgroundColor: state.isSelected ? "var(--primary)" : "inherit",
    color: state.isSelected ? "var(--white)" : "inherit",
    "&:hover": {
      backgroundColor: state.isSelected ? "var(--primary)" : "var(--light)",
    },
  }),
  menu: (base: Record<string, unknown>) => ({
    ...base,
    zIndex: 100,
    background: "var(--white)",
    color: "var(--text)",
    border: "1px solid var(--border)",
  }),
  dropdownIndicator: (base: Record<string, unknown>) => ({
    ...base,
    "& svg": {
      width: 15,
      color: "var(--text)",
    },
  }),
};

const QuoteDetails = () => {
  const {userRole} = useAuth();
  const location = useLocation();

  if (!location.state) {
    return (
      <Navigate
        to={
          userRole === RoleDescription.Patient
            ? "/orcamentos-paciente"
            : "/orcamentos-profissional"
        }
      />
    );
  }

  const quoteId = location.state as number;
  const navigate = useNavigate();
  const [quote, setQuote] = useState<Quote>({} as Quote);
  const [formModalShow, setFormModalShow] = useState(false);
  const [paymentLinkModalShow, setPaymentLinkModalShow] = useState(false);
  const [termsLinkModalShow, setTermsLinkModalShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [quoteStatusSelected, setQuoteStatusSelected] = useState<StatusOption>(
    {} as StatusOption
  );
  const [databaseFiles, setDatabaseFiles] = useState<DatabaseFile[]>([]);
  const [dateStatusSelected, setDateStatusSelected] = useState<
    Omit<StatusOption, "statusColor">
  >({} as Omit<StatusOption, "statusColor">);
  const [paymentStatusSelected, setPaymentStatusSelected] = useState<
    Omit<StatusOption, "statusColor">
  >({} as Omit<StatusOption, "statusColor">);
  const [selectFormTypeOptions, setSelectFinancingOptions] =
    useState<formTypeOptionsInterface[]>(formTypeOptions);
  const [selectedFormTypeOption, setSelectedFormTypeOption] =
    useState<formTypeOptionsInterface>(defaultFormTypeOption);
  const [formQuoteModalShow, setFormQuoteModalShow] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [formQuote, setFormQuote] = useState<FormQuote>({} as FormQuote);

  const {
    getFormQuote,
    formQuoteForm,
    setSaveFormAsDraft,
    pendingItems,
    getQuotePendingItems,
  } = useQuoteDetails();

  const groupedOptions: readonly GroupedOption[] = [
    {
      label: "Retornar status para:",
      options: quote?.previusStep?.map((step) => {
        return {
          value: step,
          label: quoteStatusTitle.get(step) as string,
          statusColor: `var(--${quoteStatusColor.get(step)})`,
        };
      }),
    },
    {
      label: "Avançar status para:",
      options: quote?.nextStep?.map((step) => {
        return {
          value: step,
          label: quoteStatusTitle.get(step) as string,
          statusColor: `var(--${quoteStatusColor.get(step)})`,
        };
      }),
    },
  ];

  const selectorStatusCustomStyles = {
    container: (base: Record<string, unknown>) => ({
      ...base,
      padding: 0,
    }),
    control: (base: Record<string, unknown>) => ({
      ...base,
      minHeight: 18,
      fontSize: "80%",
      borderColor: "var(--border)",
      cursor: "pointer",
      boxShadow: "none",
      "&:hover": {
        borderColor: "var(--border)",
      },
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      width: 120,
      fontSize: "80%",
      background: "var(--white)",
      color: "var(--text)",
      border: "1px solid var(--border)",
    }),
    option: (base: Record<string, unknown>, state: any) => ({
      ...base,
      cursor: "pointer",
      fontWeight: "bold",
      backgroundColor: state.isSelected ? "var(--primary)" : "inherit",
      color: state.isSelected ? "var(--white)" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--primary)" : "var(--light)",
      },
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      fontWeight: "bold",
    }),
    dropdownIndicator: (base: Record<string, unknown>) => ({
      ...base,
      padding: "2px",
      "& svg": {
        width: 15,
        color: "var(--text)",
      },
    }),
  };

  const selectorQuoteStatusCustomStyles = (value: StatusOption) => ({
    container: (base: Record<string, unknown>) => ({
      ...base,
      fontSize: "14px",
    }),
    control: (base: Record<string, unknown>) => ({
      ...base,
      borderRadius: 50,
      cursor: "pointer",
      background: value?.statusColor,
      paddingLeft: 8,
      paddingRight: 8,
      border: value?.statusColor,
      boxShadow: "none",
      "&:hover": {
        borderColor: value?.statusColor,
      },
    }),
    menu: (base: Record<string, unknown>) => ({
      ...base,
      maxWidth: 180,
      zIndex: 1030,
      background: "var(--white)",
      color: "var(--text)",
      border: "1px solid var(--border)",
    }),
    option: (base: Record<string, unknown>, state: any) => ({
      ...base,
      cursor: "pointer",
      fontWeight: "bold",
      color: state.data.statusColor,
      backgroundColor: state.isSelected ? "var(--primary)" : "inherit",
      "&:hover": {
        backgroundColor: state.isSelected ? "var(--primary)" : "var(--light)",
      },
    }),
    singleValue: (base: Record<string, unknown>) => ({
      ...base,
      fontWeight: "700",
      color: "var(--white)",
      fontSize: "11px",
      letterSpacing: ".05rem",
    }),
    indicatorsContainer: (base: Record<string, unknown>) => ({
      ...base,
      display: value.value === QuoteStatus.Finished ? "none" : "flex",
    }),
    dropdownIndicator: (base: Record<string, unknown>) => ({
      ...base,
      "& svg": {
        width: 15,
        color: "var(--white)",
      },
    }),
  });

  const handleFinancingChange = (newValue: {
    value: number;
    label: string;
  }) => {
    setSelectedFormTypeOption(newValue);

    if (newValue) {
      setFormQuote({form: {type: newValue.value}} as FormQuote);
    }
  };

  const closeFormQuoteStepper = () => {
    Swal.fire({
      title: "Deseja salvar como rascunho?",
      icon: "warning",
      position: "center",
      showCloseButton: true,
      showDenyButton: true,
      showConfirmButton: true,
      confirmButtonText: "Salvar",
      denyButtonText: "Não Salvar",
      reverseButtons: true,
      buttonsStyling: false,
      customClass: {
        confirmButton: "btn btn-outline-success ml-2",
        denyButton: "btn btn-outline-danger",
      },
    }).then((res) => {
      if (res.isConfirmed) {
        setSaveFormAsDraft(true);
        setFormQuoteModalShow(false);
        setActiveStep(0);

        toast.fire("Formulário foi salvo como rascunho!", "", "success");
      } else if (res.isDenied) {
        setSaveFormAsDraft(false);
        setFormQuoteModalShow(false);
        setActiveStep(0);

        toast.fire("Formulário não foi salvo como rascunho!", "", "info");
      } else {
        return;
      }
    });
  };

  const changeQuoteStatus = async (
    newStatus: SingleValue<{
      value: number;
      label: string;
      statusColor: string;
    }>
  ) => {
    setQuoteStatusSelected(newStatus as StatusOption);

    try {
      setIsLoading(true);
      await api.patch(`/Quote/${quote.id}/status`, null, {
        params: {
          newStatus: newStatus?.value,
        },
      });

      const response = await api.get(`/Quote/${quoteId}`);
      await getQuotePendingItems(quoteId);
      console.log(response.data);

      setQuote(response.data);

      toast.fire({
        icon: "success",
        title: "Status atualizado com sucesso!",
      });
    } catch (error) {

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const changeDateStatus = async (
    newStatus: SingleValue<{
      value: number;
      label: string;
    }>
  ) => {
    setDateStatusSelected(newStatus as Omit<StatusOption, "statusColor">);

    try {
      setIsLoading(true);
      await api.patch(`/Quote/${quote.id}/procedureDateStatus`, null, {
        params: {
          procedureDateStatus: newStatus?.value,
        },
      });

      const response = await api.get(`/Quote/${quoteId}`);
      await getQuotePendingItems(quoteId);

      setQuote(response.data);

      toast.fire({
        icon: "success",
        title: "Status da data do procedimento atualizado com sucesso!",
      });
    } catch (error) {

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const changePaymentStatus = async (
    newStatus: SingleValue<{
      value: number;
      label: string;
    }>
  ) => {
    setPaymentStatusSelected(newStatus as Omit<StatusOption, "statusColor">);

    const body = {
      status: newStatus?.value,
      payment_date: moment().toISOString(true),
    };

    try {
      setIsLoading(true);
      await api.patch(`/Quote/${quote.id}/Payment`, body);

      const response = await api.get(`/Quote/${quoteId}`);
      await getQuotePendingItems(quoteId);

      setQuote(response.data);

      toast.fire({
        icon: "success",
        title: "Status do pagamento atualizado com sucesso!",
      });
    } catch (error) {

      toast.fire({
        icon: "error",
        title: "Ocorreu um erro, tente novamente.",
      });
    }
  };

  const getQuote = async (quoteId: number) => {
    setIsLoading(true);

    try {
      const {data}: AxiosResponse<Quote> = await api.get(`/Quote/${quoteId}`);

      setQuote(data);
      setQuoteStatusSelected({
        value: data.quoteStatus,
        label: quoteStatusTitle.get(data.quoteStatus) as string,
        statusColor: `var(--${quoteStatusColor.get(data.quoteStatus)})`,
      });
      setDateStatusSelected({
        value: data.dateStatus,
        label: ProcedureDateStatusDescription[data.dateStatus],
      });
      setPaymentStatusSelected({
        value: data.payment.status,
        label: QuotePaymentStatusDescription[data.payment.status],
      });

    } catch (error) {
      console.error(error);
    }
  };

  const getAttachment = async (quoteId: number) => {
    setIsLoading(true);
    try {
      const {data}: AxiosResponse<FileAttachment> = await api.get(
        "/FileAttachment/find",
        {
          params: {
            idOrcamento: quoteId,
          },
        }
      );

      setDatabaseFiles(data.listFiles);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setFormQuote({
      ...formQuote,
      form: {type: selectedFormTypeOption.value},
    } as FormQuote);
  }, [selectedFormTypeOption]);

  useEffect(() => {
    const formType = formQuoteForm.map((o) => o.form.type);

    if (!formType.includes(FormTypeDescription["Financiamento Solicitante"])) {
      setSelectFinancingOptions(formTypeOptions);
      setSelectedFormTypeOption(defaultFormTypeOption);
      return;
    }

    setSelectFinancingOptions([
      {
        value: FormTypeDescription["Financiamento Avalista"],
        label: FormTypeDescription[1],
      },
    ]);
    setSelectedFormTypeOption({
      value: FormTypeDescription["Financiamento Avalista"],
      label: FormTypeDescription[1],
    });
  }, [formQuoteForm]);

  useEffect(() => {
    (async () => {
      await getQuote(quoteId);
      await getAttachment(quoteId);
      await getFormQuote(quoteId);
      setIsLoading(false);
    })();
  }, []);

  return (
    <>
      {!!isLoading && (
        <ContainerAnimation className="content">
          <LoadingScreen />
        </ContainerAnimation>
      )}

      {!isLoading && (
        <ContainerAnimation className="content">
          {/* Cabeçalho */}
          <div className="bg-white">
            <div className="container">
              <div className="row align-items-center pb-3">
                {/* Título */}
                <div className="col-12 col-lg">
                  <h1>
                    <LinkButton className="fs-1" onClick={() => navigate(-1)}>
                      <OverlayTrigger
                        placement="top"
                        overlay={<Tooltip>Voltar</Tooltip>}
                      >
                        <i className="uil uil-arrow-left mr-2" />
                      </OverlayTrigger>
                    </LinkButton>
                    Orçamento - #{quote?.id}
                  </h1>
                </div>

                {/* Ações */}
                <div className="col col-auto">
                  <div className="row g-2">
                    {/* Status */}
                    {quote?.actions?.includes(QuoteActions.EditStatus) && (
                      <div className="col col-auto">
                        <ReactSelect
                          styles={selectorQuoteStatusCustomStyles(
                            quoteStatusSelected
                          )}
                          options={groupedOptions}
                          value={quoteStatusSelected}
                          onChange={changeQuoteStatus}
                          isSearchable={false}
                          formatGroupLabel={FormatGroupLabel}
                          noOptionsMessage={() => null}
                          className="text-start react-select-uppercase"
                        />
                      </div>
                    )}

                    {/* Link de Pagamento */}
                    {!!quote?.hasPaymentLink &&
                      (quote?.payment?.linkAction ===
                        PaymentLinkAction.Copy ||
                        quote?.payment?.linkAction ===
                        PaymentLinkAction.CopyPay) &&
                      !quote?.isTerminalPDQ && (
                        <div className="col col-auto">
                          <Button
                            className="btn-sm btn-outline-info"
                            onClick={() => setPaymentLinkModalShow(true)}
                          >
                            <i className="uil uil-link-add" />

                            <span className="d-none d-sm-inline ml-2">
                              Link de Pagamento
                            </span>
                          </Button>
                        </div>
                      )}

                    {/* Editar Orçamento */}
                    {quote?.actions?.includes(QuoteActions.Edit) &&
                      userRole === RoleDescription.Admin && (
                        <div className="col col-auto">
                          <Button
                            className="btn-sm btn-outline-primary"
                            onClick={() => setFormModalShow(true)}
                          >
                            <i className="uil uil-edit-alt" />

                            <span className="d-none d-sm-inline ml-2">
                              Editar Orçamento
                            </span>
                          </Button>
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container">
            {/* Linha do Tempo */}
            <div className="timeline d-flex justify-content-center flex-column flex-md-row border-left border-md-top border-md-left-0 border-primary ml-5 ml-md-0">
              <TimeLineStep
                step={QuoteStatus.Quote}
                quoteStatus={quote?.quoteStatus}
              />

              {quote?.quoteStatus === QuoteStatus.Analyzing ? (
                <TimeLineStep
                  step={QuoteStatus.Analyzing}
                  quoteStatus={quote?.quoteStatus}
                  isPendingItems={!!pendingItems?.length}
                />
              ) : (
                <TimeLineStep
                  step={QuoteStatus.WaitingApproval}
                  quoteStatus={quote?.quoteStatus}
                  paymentMethod={quote?.payment?.method}
                  isPendingItems={!!pendingItems?.length}
                />
              )}

              <TimeLineStep
                step={QuoteStatus.Approved}
                quoteStatus={quote?.quoteStatus}
              />

              <TimeLineStep
                step={QuoteStatus.Schedule}
                quoteStatus={quote?.quoteStatus}
              />

              <TimeLineStep
                step={QuoteStatus.Finished}
                quoteStatus={quote?.quoteStatus}
              />
            </div>

            {/* Informações da mensagem se disponível */}
            {!!quote.infoMessage && (
              <p className="mt-5">
                <i className="uil uil-info-circle align-bottom display-6 me-1"></i>

                <span>{quote.infoMessage}</span>
              </p>
            )}

            {/* Lista de Pendências */}
            {!!pendingItems?.length && (
              <Accordion defaultActiveKey="0">
                <Accordion.Item eventKey="0">
                  <Accordion.Header>
                    <i className="uil uil-exclamation-circle display-6 text-danger me-1"></i>
                    <span className="text-danger">Pendências</span>
                  </Accordion.Header>
                  <Accordion.Body>
                    <ul className="list-group">
                      {pendingItems?.map((pendingItem, index) => (
                        <li
                          key={index}
                          className="list-group-item border-0 d-flex flex-column flex-md-row align-items-md-center gap-2"
                        >
                          <div>
                            <span>{pendingItem.description}</span>
                          </div>

                          <div>
                            {/* {quote?.actions?.includes(
                              QuoteActions.ShareServiceContractProvisionLink
                            ) &&
                              pendingItem.action ===
                                QuoteActions.AgreeServiceContractProvision && (
                                <Button
                                  className="btn btn-sm btn-outline-primary"
                                  onClick={() => setTermsLinkModalShow(true)}
                                >
                                  Termos
                                </Button>
                              )} */}

                            {quote?.actions?.includes(pendingItem.action) && (
                              <Link
                                className="btn btn-sm btn-outline-primary"
                                to={`/termos/${quote?.code}`}
                              >
                                Termos
                              </Link>
                            )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            )}

            {/* Informações do Orçamento */}
            <div className="row mt-5">
              <div className="col-12 col-lg-6 col-xl-7 pb-4 pb-md-0">
                <div className="form-box p-3 p-sm-4 p-xl-5 mb-4">
                  <h5 className="mb-4">Informações do Orçamento</h5>

                  <h6 className="text-muted text-uppercase small">Cliente:</h6>
                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    <h6 className="mb-0 text-body">{quote.patient?.name}</h6>
                  </div>

                  <h6 className="text-muted text-uppercase small">
                    Profissional:
                  </h6>
                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    <h6 className="mb-0 text-body opacity-50 user-select-none">
                      {quote.professional?.name ?? "NÃO INFORMADO..."}
                    </h6>
                  </div>

                  <h6 className="text-muted text-uppercase small">
                    Instituição:
                  </h6>
                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    <h6 className="mb-0 text-body">{quote.company?.name}</h6>
                  </div>

                  <div className="d-flex justify-content-between align-items-end mb-2">
                    <h6 className="text-muted text-uppercase small mb-0">
                      Procedimento:
                    </h6>
                    {quote?.actions?.includes(
                      QuoteActions.ModifyProcedureDateStatus
                    ) && (
                        <ReactSelect
                          options={dateStatusOptions}
                          value={dateStatusSelected}
                          isSearchable={false}
                          onChange={changeDateStatus}
                          styles={selectorStatusCustomStyles}
                          className="text-start"
                          isOptionDisabled={(option) =>
                            option.value === dateStatusSelected.value
                          }
                        />
                      )}
                  </div>
                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    <h6 className="text-body">{quote.description}</h6>
                    <p className="mb-0">
                      <strong>Data:</strong>{" "}
                      {moment(quote?.date).format("DD/MM/YYYY")}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between align-items-end mb-2">
                    <h6 className="text-muted text-uppercase small mb-0">
                      Pagamento:
                    </h6>
                    {quote?.actions?.includes(
                      QuoteActions.ModifyPaymentStatus
                    ) && (
                        <ReactSelect
                          options={
                            quote.payment?.status ===
                              QuotePaymentStatusDescription.Pago
                              ? [
                                ...quotePaymentStatusOptions,
                                {
                                  value: QuotePaymentStatusDescription.Repasse,
                                  label: "Repasse ao prestador realizado",
                                },
                              ]
                              : quotePaymentStatusOptions
                          }
                          value={paymentStatusSelected}
                          isSearchable={false}
                          onChange={changePaymentStatus}
                          styles={selectorStatusCustomStyles}
                          className="text-start"
                          isOptionDisabled={(option) =>
                            option.value === quote.payment.status
                          }
                        />
                      )}
                  </div>

                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    {quote.payment?.method === PaymentMethod.Financing && (
                      <>
                        {!!quote.payment?.finalValue && (
                          <div className="alert alert-success text-center">
                            <span>
                              O proponente passou na primeira fase de análise.
                              Seu processo está em fase de avaliação documental
                              junto à equipe de gestão de crédito da instituição
                              financeira. Acompanhe o andamento no chat.
                            </span>
                          </div>
                        )}

                        {/* {!quote.payment.finalValue && (
                          <div className="alert alert-danger text-center">
                            <span className="mb-0">
                              Análise de crédito negada, motivo real não
                              especificado pelo banco devido a sigilo bancário.
                            </span>
                            <br />
                            <br />
                            <span className="mb-0">
                              Possíveis motivos: Score baixo, restrições no CPF,
                              alto comprometimento de renda.
                            </span>
                          </div>
                        )} */}
                      </>
                    )}

                    <h6 className="text-body">
                      Valor:{" "}
                      <span className="text-primary">
                        {formatPrice(quote.payment?.value)}
                      </span>
                    </h6>

                    <p className="mb-0">
                      <strong>Método de Pagamento:</strong>{" "}
                      {PaymentMethodDescription[quote.payment?.method]}
                    </p>
                    {quote.payment?.paymentDate &&
                      moment(quote.payment?.paymentDate).format(
                        "DD/MM/YYYY"
                      ) !== "01/01/0001" && (
                        <p className="mb-0">
                          <strong>Data Pagamento:</strong>{" "}
                          {moment(quote.payment?.paymentDate).format(
                            "DD/MM/YYYY"
                          )}
                        </p>
                      )}

                    {quote.payment?.brand && (
                      <p className="mb-0">
                        <strong>Bandeira:</strong>{" "}
                        <div className="d-inline-block bg-white rounded border p-1">
                          <img
                            src={`${process.env.PUBLIC_URL}/bandeiras/${quote.payment?.brand.toLowerCase()}.svg`}
                            className="img-fluid"
                            width={60}
                          />
                        </div>
                      </p>
                    )}

                    <p className="mb-0">
                      <strong>Quantidade de Parcelas:</strong>{" "}
                      {quote.payment?.parcelQtd}
                    </p>

                    {quote.payment?.parcelValue !== null && (
                      <p className="mb-0">
                        <strong>Valor da Parcela:</strong>{" "}
                        {formatPrice(quote.payment?.parcelValue)}
                      </p>
                    )}

                    {quote.payment?.feeMonthly !== null && (
                      <p className="mb-0">
                        <strong>Taxa Mensal:</strong>{" "}
                        {formatAsPercent(quote.payment?.feeMonthly)}
                      </p>
                    )}

                    {quote.payment?.feeAnnual !== null && (
                      <p className="mb-0">
                        <strong>Taxa Anual:</strong>{" "}
                        {formatAsPercent(quote.payment?.feeAnnual)}
                      </p>
                    )}
                    

                    <p
                      className={`mb-0
                        ${quote?.hasLimitDateAlert ? "text-danger" : ""}`}
                    >
                      <strong>Data Limite:</strong>{" "}
                      {moment(quote?.limitDate).format("DD/MM/YYYY")}
                    </p>

                    <p className="mb-0">
                      <strong>Responsável pela Taxa:</strong>{" "}
                      {PaymentFeeOwner[quote.payment?.paymentFeeOwner]}
                    </p>

                    <p className="mb-0">
                      <strong>Valor de Compensação:</strong>{" "}
                      {!quote.payment?.compensationValue
                        ? "Não Informado"
                        : formatPrice(quote.payment?.compensationValue)}
                    </p>

                    <p>
                      <strong>Data de Compensação:</strong>{" "}
                      {!quote.payment?.paymentLiquidationDate
                        ? "Não Informado"
                        : moment(quote.payment?.paymentLiquidationDate).format(
                          "DD/MM/YYYY"
                        )}
                    </p>
                    
                    {quote.payment?.cetCost !== null && (
                      <p className="mb-0">
                        <strong>Custo CET:</strong>{" "}
                        {formatAsPercent(quote.payment?.cetCost)}
                      </p>
                    )}

                    {quote.payment?.finalValue !== null && (
                      <>
                        <h4 className="text-end text-body mb-0 mt-2">
                          Valor Total:{" "}
                          <span className="text-primary">
                            {formatPrice(quote.payment?.finalValue)}
                          </span>
                        </h4>
                      </>
                    )}

                  </div>

                  <h6 className="text-muted text-uppercase small">
                    Data de Criação:
                  </h6>
                  <div className="rounded-3 border p-3 pb-0 mb-4">
                    <h6 className="mb-0 text-body">
                      {moment(quote.creationDate).format("DD/MM/YYYY")}
                    </h6>
                  </div>
                </div>
              </div>

              {/* Chat do Orçamento */}
              {quote?.actions?.includes(QuoteActions.ViewQuoteChat) && (
                <Accordion
                  defaultActiveKey="0"
                  className="col-12 col-lg-6 col-xl-5 sticky-top h-50"
                  style={{zIndex: 1}}
                >
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Chat</Accordion.Header>
                    <Accordion.Body className="p-0">
                      <Chat quoteId={quoteId} quoteActions={quote.actions} />
                    </Accordion.Body>
                  </Accordion.Item>
                  {quote?.company?.enableCheckList && (
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Checklist</Accordion.Header>
                      <Accordion.Body className="pb-2">
                        <Checklist quoteId={quoteId} />
                      </Accordion.Body>
                    </Accordion.Item>
                  )}
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Histórico</Accordion.Header>
                    <Accordion.Body className="p-0">
                      <AuditLogList quoteId={quoteId} />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              )}

              {/* Informações do Complementares */}
              <div className="col-12 col-md-6 col-xl-7 mb-4">
                <div className="form-box p-3 p-sm-4 p-xl-5">
                  <h5 className="mb-4">Informações Complementares</h5>
                  <div className="rounded-3 border px-3 pb-0 mb-4 overflow-files">
                    {!formQuoteForm.length ? (
                      <p className="my-2">Nenhum formulário encontrado</p>
                    ) : (
                      <>
                        {formQuoteForm.map((formQuote) => (
                          <FormQuoteCard
                            key={formQuote.id}
                            formQuote={formQuote}
                            quoteId={quoteId}
                          />
                        ))}
                      </>
                    )}
                  </div>
                  {quote?.actions?.includes(QuoteActions.AddExtraInfoForm) && (
                    <Accordion>
                      <Card>
                        <Card.Header className="text-end">
                          <CustomToggle
                            title="Adicionar Formulário"
                            eventKey="0"
                          />
                        </Card.Header>

                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <div className="pb-2">
                              <Select
                                options={selectFormTypeOptions}
                                onChange={(newValue) => {
                                  handleFinancingChange(
                                    newValue as {value: number; label: string}
                                  );
                                }}
                                placeholder="Selecione o Tipo do Financiamento"
                                isClearable={true}
                                value={selectedFormTypeOption}
                                styles={customStyles}
                                theme={customTheme}
                              />
                            </div>

                            <div className="text-end pt-5">
                              <Button
                                className="btn-sm btn-primary"
                                disabled={isLoading || !selectedFormTypeOption}
                                onClick={() => setFormQuoteModalShow(true)}
                              >
                                Adicionar
                                {!!isLoading && (
                                  <CircularProgress
                                    color="inherit"
                                    size={12}
                                    className="align-text-top ms-2"
                                  />
                                )}
                              </Button>
                            </div>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  )}
                </div>
              </div>

              {/* Arquivos do Orçamento */}
              <div className="col-12 col-md-6 col-xl-7">
                <div className="sticky-top">
                  <div className="form-box p-3 p-sm-4 p-xl-5">
                    <h5 className="mb-4">
                      Arquivos do Orçamento
                      {quote.payment?.method === PaymentMethod.Financing && (
                        <OverlayTrigger
                          placement="left"
                          overlay={
                            <Tooltip>
                              <RequiredDocuments />
                            </Tooltip>
                          }
                        >
                          <i className="uil uil-info-circle ml-2 opacity-75 text-body" />
                        </OverlayTrigger>
                      )}
                    </h5>

                    <div className="rounded-3 border px-3 pb-0 mb-4 overflow-files">
                      {!databaseFiles.length ? (
                        <p className="my-2">Nenhum arquivo encontrado</p>
                      ) : (
                        <>
                          {databaseFiles.map((file) => (
                            <FileCard
                              key={file.id}
                              file={file}
                              setDatabaseFiles={setDatabaseFiles}
                              quoteId={quoteId}
                              quoteActions={quote.actions}
                            />
                          ))}
                        </>
                      )}
                    </div>

                    {/* Adicionar Arquivos */}
                    {quote?.actions?.includes(QuoteActions.AddAttachment) && (
                      <Accordion>
                        <Card>
                          <Card.Header className="text-end">
                            <CustomToggle
                              title="Adicionar Arquivos"
                              eventKey="0"
                            />
                          </Card.Header>

                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <FileAddForm
                                quoteId={quote.id}
                                setDatabaseFiles={setDatabaseFiles}
                              />
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ContainerAnimation>
      )}

      {/* Modal para editar orçamento */}
      <ModalComponent
        title="Editar Orçamento"
        show={formModalShow}
        onHide={() => setFormModalShow(false)}
      >
        {userRole === RoleDescription.Professional && (
          <ProfessionalQuoteEditForm
            onHide={() => setFormModalShow(false)}
            quote={quote}
            setQuote={setQuote}
          />
        )}

        {userRole === RoleDescription.Patient && (
          <PatientQuoteEditForm
            onHide={() => setFormModalShow(false)}
            quote={quote}
            setQuote={setQuote}
          />
        )}

        {userRole === RoleDescription.Admin && (
          <QuoteEditForm
            onHide={() => setFormModalShow(false)}
            quote={quote}
            setQuote={setQuote}
          />
        )}

        {userRole === RoleDescription.Secretary && (
          <SecretaryQuoteEditForm
            onHide={() => setFormModalShow(false)}
            quote={quote}
            setQuote={setQuote}
          />
        )}
      </ModalComponent>

      {/* Modal de Link de Pagamento */}
      <ModalComponent
        title="Link de Pagamento"
        show={paymentLinkModalShow}
        onHide={() => setPaymentLinkModalShow(false)}
        size="lg"
      >
        <PaymentLink
          onHide={() => setPaymentLinkModalShow(false)}
          quoteId={quote?.id}
          linkAction={quote?.payment?.linkAction}
        />
      </ModalComponent>

      {/* Modal de Envio de link de termos */}
      <ModalComponent
        title="Link de Termos"
        show={termsLinkModalShow}
        onHide={() => setTermsLinkModalShow(false)}
      >
        <TermsLink />
      </ModalComponent>

      {/* Modal FormQuote Stepper */}
      <ModalComponent
        title={`Adicionar Formulário do ${selectedFormTypeOption?.label}`}
        show={formQuoteModalShow}
        onHide={() => closeFormQuoteStepper()}
        size="xl"
      >
        <ModalBodyComponentStepper
          activeStep={activeStep}
          steps={[
            {
              label: "Informações Pessoais",
              component: (
                <Step1PersonalInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={formQuote}
                  setFormQuote={setFormQuote}
                  onHide={() => setFormQuoteModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Create}
                />
              ),
            },
            {
              label: "Endereço",
              component: (
                <Step2AddessInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={formQuote}
                  setFormQuote={setFormQuote}
                  onHide={() => setFormQuoteModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Create}
                />
              ),
            },
            {
              label: "Dados Profissionais",
              component: (
                <Step3ProfessionalInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={formQuote}
                  setFormQuote={setFormQuote}
                  onHide={() => setFormQuoteModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Create}
                />
              ),
            },
            {
              label: "Outros",
              component: (
                <Step4OthersInfoForm
                  setActiveStep={setActiveStep}
                  formQuote={formQuote}
                  setFormQuote={setFormQuote}
                  onHide={() => setFormQuoteModalShow(false)}
                  quoteId={quoteId}
                  action={ActionFormQuote.Create}
                />
              ),
            },
          ]}
        />
      </ModalComponent>
    </>
  );
};

export default QuoteDetails;
